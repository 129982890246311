/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.4.1/dist/css/bootstrap.min.css
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.css
 * - /npm/qtip2@3.0.3/dist/jquery.qtip.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
